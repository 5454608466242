<template>
  <v-card class="mx-auto" max-width="640">
    <v-img :src="require('@/assets/hond.jpg')"></v-img>
    <v-card-title>{{ $t("about.title") }}</v-card-title>
    <v-card-text>
      <p>
        {{ $t("about.message.body") }}
      </p>
      <br />
      Team Sommerset Outdoor <br />
      {{ $t("about.message.signature") }}
    </v-card-text>
    <v-divider></v-divider>

    <v-card-text>
      <strong>{{ $t("about.adviceTitle") }}</strong
      ><br />
      {{ $t("about.advice") }}
    </v-card-text>
  </v-card>
</template>
