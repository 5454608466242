import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createI18n } from "vue-i18n";
import { createVuetify } from "vuetify";
import "vuetify/styles";
import "@mdi/font/css/materialdesignicons.css";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { loadFonts } from "./plugins/webfontloader";

const messages = {
  nl: require("./locales/nl.json"),
  de: require("./locales/de.json"),
  en: require("./locales/en.json"),
  fr: require("./locales/fr.json"),
};

const i18n = createI18n({
  locale: "nl", // set locale
  fallbackLocale: "nl", // set fallback locale
  messages, // set locale messages
});

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: "myCustomTheme",
    themes: {
      myCustomTheme: {
        dark: false,
        colors: {
          primary: "#4b3620",
          secondary: "#292960",
          accent: "#8c9eff",
          error: "#b71c1c",
          info: "#2196F3",
          success: "#4CAF50",
          warning: "#FFC107",
        },
      },
    },
  },
});

loadFonts();

createApp(App).use(router).use(i18n).use(store).use(vuetify).mount("#app");
