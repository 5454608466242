<template>
  <v-app>
    <nav-bar></nav-bar>
    <v-main>
      <div class="pt-4 pb-4">
        <router-view />
      </div>
    </v-main>
    <footer-component></footer-component>
  </v-app>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import FooterComponent from "./components/FooterComponent.vue";

export default {
  name: "App",
  components: {
    NavBar,
    FooterComponent,
  },
  data: () => ({
    //
  }),
};
</script>
