<template>
  <v-card class="mx-auto" max-height="170vh" max-width="95vw">
    <v-card-text>
      <v-window v-model="window" show-arrows>
        <v-window-item v-for="n in list_of_pages.length" :key="n">
          <v-row
            v-if="list_of_pages[n - 1].length > 1"
            no-gutters
            class="align-center justify-center"
          >
            <v-col sm="6" cols="12">
              <v-img
                v-if="typeof list_of_pages[n - 1][0] == 'string'"
                height="80vh"
                :src="
                  require(`@/assets/${asset_path}${list_of_pages[n - 1][0]}`)
                "
              ></v-img>
              <div v-else width="100%" height="100%" class="pl-16">
                <div class="text-h5">
                  {{ list_of_pages[n - 1][0]["name"] }}
                </div>
                <v-row>
                  <v-col md="8" cols="12" class="mt-4">
                    <p>
                      {{ computedDescriptions[n - 1] }}
                    </p>
                  </v-col>
                  <v-col md="4" cols="12">
                    <v-list density="compact">
                      <v-list-item>
                        <v-list-item-title>
                          {{ $t("viewer.color") }}
                        </v-list-item-title>
                        {{ computedColors[n - 1] }}
                      </v-list-item>
                      <v-list-item v-if="'height' in list_of_pages[n - 1][0]">
                        <v-list-item-title>
                          {{ $t("viewer.height") }}
                        </v-list-item-title>
                        {{ list_of_pages[n - 1][0]["height"] }} cm
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>
                          {{ $t("viewer.sizes") }}
                        </v-list-item-title>
                        {{ list_of_pages[n - 1][0]["size_min"] }} -
                        {{ list_of_pages[n - 1][0]["size_max"] }}
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <div class="pt-2">
                  {{ $t("viewer.price") }}:
                  <div class="font-weight-black text-h6">
                    €{{ list_of_pages[n - 1][0]["price"] }}
                  </div>
                </div>
              </div>
            </v-col>
            <v-col sm="6" cols="12">
              <v-img
                height="80vh"
                :src="
                  require(`@/assets/${asset_path}${list_of_pages[n - 1][1]}`)
                "
              ></v-img>
            </v-col>
          </v-row>
          <v-img
            v-else
            height="80vh"
            :src="require(`@/assets/${asset_path}${list_of_pages[n - 1][0]}`)"
          ></v-img>
        </v-window-item>
      </v-window>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    window: 0,
  }),
  props: {
    asset_path: {
      type: String,
      required: true,
    },
    list_of_pages: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getLocaleString(key) {
      return this.$t(key);
    },
  },
  computed: {
    computedDescriptions() {
      var descriptions = [];
      for (var i = 0; i < this.list_of_pages.length; i++) {
        if (this.list_of_pages[i].length > 1) {
          descriptions.push(
            this.getLocaleString(this.list_of_pages[i][0]["description"])
          );
        } else {
          descriptions.push(null);
        }
      }
      return descriptions;
    },
    computedColors() {
      var colors = [];
      for (var i = 0; i < this.list_of_pages.length; i++) {
        if (this.list_of_pages[i].length > 1) {
          colors.push(this.getLocaleString(this.list_of_pages[i][0]["color"]));
        } else {
          colors.push(null);
        }
      }
      return colors;
    },
  },
};
</script>
