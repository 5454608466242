<template>
  <v-footer class="my-footer d-flex flex-column" color="primary">
    <v-row justify="space-around">
      <v-col md="6" cols="12">
        <v-card class="justify-center align-center" variant="plain">
          <v-card-text>
            {{ $t("footer") }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="4" cols="6">
        <div class="justify-center align-center fill-height" variant="text">
          <v-btn
            variant="plain"
            prepend-icon="mdi-phone"
            href="tel: +31243231600"
            >+31 243231600
          </v-btn>
          <br />

          <v-btn
            variant="plain"
            prepend-icon="mdi-phone"
            href="tel: +31611164309"
            >+31 611164309
          </v-btn>
          <br />

          <v-btn
            variant="plain"
            prepend-icon="mdi-map-marker"
            href="https://maps.app.goo.gl/WWTZJGcsKQBgmBpp7"
          >
            Eugene Luckerstraat 12 <br />
            6522HX, Nijmegen
          </v-btn>
          <br />
          <v-btn
            variant="plain"
            prepend-icon="mdi-email"
            href="mailto: info@sommersetoutdoor.nl"
            >info@sommersetoutdoor.nl</v-btn
          >
        </div>
      </v-col>
    </v-row>
  </v-footer>
</template>

<style>
.my-footer {
  flex: 0 0 auto;
}
</style>
