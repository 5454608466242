<template>
  <v-card class="mx-auto" max-width="900">
    <v-alert
      color="secondary"
      icon="mdi-badge-account-horizontal-outline"
      dense
      outlined
      closable
    >
      {{ $t("dealers.alert") }}
    </v-alert>
    <v-card-title class="d-flex align-center pe-2">
      {{ $t("menu.dealers") }}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        density="compact"
        label="Search"
        prepend-inner-icon="mdi-magnify"
        variant="solo-filled"
        flat
        hide-details
        single-line
      ></v-text-field>
    </v-card-title>

    <v-divider></v-divider>

    <v-data-table
      v-model:search="search"
      :items="dealers"
      :headers="tableHeaders"
      :row-props="rowProps"
    >
      <template v-slot:item.country="{ item }">
        <div v-if="'country' in item">{{ item.country }}</div>
        <div v-else>NL</div>
      </template>

      <template v-slot:item.contact="{ item }">
        <div class="d-flex">
          <div v-if="'website' in item">
            <v-btn
              icon="mdi-web"
              :href="`${item.website}`"
              density="comfortable"
              flat
            ></v-btn>
          </div>
          <div v-if="'phone' in item">
            <v-btn
              icon="mdi-phone"
              :href="`tel: ${item.phone}`"
              density="comfortable"
              flat
            ></v-btn>
          </div>
        </div>
      </template>
      <!-- <template v-slot:item.hasBoatshoes="{ item }">
        <div v-if="'hasBoatshoes' in item">
          <v-icon color="green">mdi-check-circle-outline</v-icon>
        </div>
        <div v-else>
          <v-icon color="red">mdi-close-circle-outline</v-icon>
        </div>
      </template>
      <template v-slot:item.hasBoots="{ item }">
        <div v-if="'hasBoots' in item">
          <v-icon color="green">mdi-check-circle-outline</v-icon>
        </div>
        <div v-else>
          <v-icon color="red">mdi-close-circle-outline</v-icon>
        </div>
      </template> -->
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      dealers: [
        {
          location: "Aalsmeer",
          dealer: "Horse Paradise Stables",
          website: "http://www.horseparadise.com",
        },
        {
          location: "Aalsmeer",
          dealer: "Dragt Watersport",
          website: "http://www.dragtwatersport.nl",
        },
        {
          location: "Alblasserdam",
          dealer: "Antilope Outdoor",
          website: "http://www.antilopeoutdoor.nl",
        },
        {
          location: "Alkmaar",
          dealer: "Waardijk Schoenen",
          website: "http://www.waardijk.nl",
        },
        {
          location: "Ameland",
          dealer: "Kienstra Schoenen en Outdoor",
          website: "http://www.kienstraschoenen.nl",
        },
        {
          location: "Amersfoort",
          dealer: "Outdoor Gear",
          website: "http://www.outdoor-gear.org",
        },
        {
          location: "Arnhem",
          dealer: "Watersportwinkel Arnhem",
          website: "http://www.watersportwinkelarnhem.nl",
        },
        {
          location: "Arnhem",
          dealer: "Tromp Hengelsport",
          website: "http://www.tromphengelsport.nl",
        },
        {
          location: "Arnhem",
          dealer: "Bootland.nl",
          website: "http://www.bootland.nl",
        },
        {
          location: "Assen",
          dealer: "Tuinland",
          website: "http://www.tuinland.nl",
        },
        {
          location: "Baarle Hertog",
          dealer: "Theeuwes Dierenspeciaalzaak",
          website: "http://www.theeuwesdierenspeciaalzaak.nl",
          county: "BE",
        },
        {
          location: "Bakel",
          dealer: "Ruitersport Verhoeven",
          website: "http://www.ruitersportverhoeven.nl",
        },
        {
          location: "Barneveld",
          dealer: "Intratuin Barneveld",
          website: "http://www.intratuin.nl",
        },
        {
          location: "Bayeux",
          dealer: "L'Atelier du Chausseur",
          phone: "Tel. +33-231161978",
          country: "FR",
        },
        {
          location: "Beneden Leeuwen",
          dealer: "Tuincentrum GroenRijk",
          website: "https://www.groenrijkbenedenleeuwen.nl/",
        },
        {
          location: "Berkel en Rodenrijs",
          dealer: "Damesjachtkleding.nl",
          website: "http://www.damesjachtkleding.nl",
        },
        {
          location: "Berlicum",
          dealer: "Grandeur ruitersport",
          website: "http://www.grandeurruitersport.nl",
        },
        {
          location: "Beuningen",
          dealer: "Tuincentrum Bull",
          website: "http://www.tuincentrumbull.nl",
        },
        {
          location: "Beverwijk",
          dealer: "Kuipers Nautic",
          website: "http://www.kuipersnautic.nl",
        },
        {
          location: "Bisamberg",
          dealer: "Maritimo GmbH",
          website: "http://www.maritimo.at",
          country: "AT",
        },
        {
          location: "Blankenberge",
          dealer: "North Sea Boating",
          website: "http://www.northseaboating.com",
          country: "BE",
        },
        {
          location: "Blaricum",
          dealer: "De Scholekster",
          website: "http://www.descholeksterblaricum.nl",
        },
        {
          location: "Brouwershaven",
          dealer: "Krokus",
          website: "http://www.bloemenshopkrokus.nl",
        },
        {
          location: "Bunde",
          dealer: "Schuhhaus W.Vormbrock",
          website: "http://www.bootschuhe.de",
          country: "DE",
        },
        {
          location: "Bunnik",
          dealer: "Mets Jachthuis",
          website: "http://www.metsjachthuis.nl",
        },
        {
          location: "Callantsoog",
          dealer: "Bij Breghtje",
          phone: "-",
        },
        {
          location: "De Panne",
          dealer: "Northsea Shoes",
          website: "https://www.northsea.be",
          country: "BE",
        },
        {
          location: "Deventer",
          dealer: "Paardenhoek",
          website: "http://www.phruitersport.nl",
        },
        {
          location: "Deventer",
          dealer: "Necessaries by Marlou",
          website: "https://www.necessariesbymarlou.nl",
        },
        {
          location: "Doetinchem",
          dealer: "Sangers Ruitersport",
          website: "http://www.sangersruitersport.nl",
        },
        {
          location: "Drachten",
          dealer: "Kamsma Schoenen",
          website: "https://www.kamsmaschoenen.nl",
        },
        {
          location: "D\u00fcsseldorf",
          dealer: "Lenz Rega Port",
          website: "https://www.lenz-rega-port.de",
          country: "DE",
        },
        {
          location: "Egmond aan Zee",
          dealer: "Egmond Plaza",
          website: "http://www.egmondplaza.nl",
        },
        {
          location: "Eindhoven",
          dealer: "Fashionbootz",
          website: "http://www.fashionbootz.nl",
        },
        {
          location: "Enter",
          dealer: "Kato Fashion",
          website: "http://www.kato-fashion-living.nl",
        },
        {
          location: "Foudgum",
          dealer: "Henk Olivier",
          website: "http://www.henkolivier.nl",
        },
        {
          location: "Geleen",
          dealer: "Limo Ruitersport",
          website: "http://www.limoruitersport.nl",
        },
        {
          location: "Gennep",
          dealer: "Het Hof Mode",
          website: "http://www.hethofmode.nl",
        },
        {
          location: "Gilze",
          dealer: "Nauticashop",
          website: "http://www.nauticashop.nl",
        },
        {
          location: "Groesbeek",
          dealer: "Villastraa",
          website: "http://www.villastrada.nl",
        },
        {
          location: "Groesbeek",
          dealer: "Teezz Jeanswear",
          website: "https://www.teezz-jeanswear.nl",
        },
        {
          location: "Groesbeek",
          dealer: "E van E fashion",
          website: "https://www.evanemode.nl",
        },
        {
          location: "Grou",
          dealer: "Boersma Schoenen",
          website: "http://www.boersmaschoenen.nl",
        },
        {
          location: "Gulpen",
          dealer: "Waidmann",
          website: "http://www.waidmann-shop.com",
        },
        {
          location: "Haarlem",
          dealer: "De Bode",
          website: "http://www.de-bode.nl",
        },
        {
          location: "Halle",
          dealer: "Ruitersport de Smidse",
          website: "http://www.ruitersportdesmidse.nl",
        },
        {
          location: "Hamburg",
          dealer: "Steinmetz und Hehl GmbH",
          website: "https://www.steinmetz-hehl.de/",
          country: "DE",
        },
        {
          location: "Hattingen",
          dealer: "Der Wassersportladen",
          website: "https://www.der-wassersportladen.de/",
          country: "DE",
        },
        {
          location: "Heeg",
          dealer: "Doldrums Fun & Fashion",
          phone: "0515-443458",
        },
        {
          location: "Heeg",
          dealer: "De Jong Watersport",
          website: "http://www.dejongwatersport.nl",
        },
        {
          location: "Heemskerk",
          dealer: "Dierenwereld van Someren",
          website: "http://www.dierenwereldvansomeren.nl",
        },
        {
          location: "Helsinki",
          dealer: "Marnela Oy",
          website: "http://www.marnela.fi",
          country: "FI",
        },
        {
          location: "Hengelo (Ov)",
          dealer: "DierenwinkelXL",
          website: "http://www.dierenwinkelxl.nl",
        },
        {
          location: "Hoorn",
          dealer: "Hoorn Maritiem",
          website: "http://www.maritiemhoorn.nl",
        },
        {
          location: "Huizen",
          dealer: "Firma Baard",
          website: "http://www.baard-groep.nl",
        },
        {
          location: "Ingber",
          dealer: "Euregiohunt",
          website: "http://www.euregiohunt.com",
        },
        {
          location: "Joure",
          dealer: "Boersma Schoenen",
          website: "http://www.boersmaschoenen.nl",
        },
        {
          location: "Kaatsheuvel",
          dealer: "The Gearshed",
          website: "http://www.gearshed.nl",
        },
        {
          location: "Kastrup",
          dealer: "Hellers Aps",
          website: "http://www.hellers.dk",
          country: "DK",
        },
        {
          location: "Katwijk",
          dealer: "Karoe",
          website: "http://www.karoe.nl",
        },
        {
          location: "Knokke-Heist",
          dealer: "Blue shoes",
          website: "https://www.blue-shoes.be",
          country: "BE",
        },
        {
          location: "Koudum",
          dealer: "M.Boxum",
          phone: "0514-522145",
        },
        {
          location: "Kropswolde",
          dealer: "Allround Watersport Meerwijck",
          website: "http://www.allroundwatersport.nl",
        },
        {
          location: "Kuinre",
          dealer: "JachtXL",
          website: "http://www.jachtxl.nl",
        },
        {
          location: "Landgraaf",
          dealer: "Ruitersport Viehoff",
          website: "http://www.ruitersportviehoff.nl",
        },
        {
          location: "Landgraaf",
          dealer: "Hendriks Schoenmode & Wandelsport",
          website: "http:// www.hendrikslandgraaf.nl",
        },
        {
          location: "Laren (NH)",
          dealer: "Hofstee Schoenen BV",
          website: "http://www.hofsteeschoenen.nl",
        },
        {
          location: "Leeuwarden",
          dealer: "Kamsma Schoenen",
          website: "https://www.kamsmaschoenen.nl",
        },
        {
          location: "Leiden",
          dealer: "Horseshop de Bles",
          website: "http://www.de-bles.nl",
        },
        {
          location: "Leidschendam",
          dealer: "Ad Spek Watersport",
          website: "http://www.adspekwatersport.nl",
        },
        {
          location: "Lemelerveld",
          dealer: "Riders House",
          website: "http://www.ridershouse.nl",
        },
        {
          location: "Lemmer",
          dealer: "Skipperland",
          website: "http://www.skipperland.nl",
        },
        {
          location: "Lierop",
          dealer: "Ruitersport Ingrid van Berlo",
          website: "http://www.ingridvanberlo.nl",
        },
        {
          location: "Lochem",
          dealer: "Country Lochem",
          website: "http://www.countrylochem.nl",
        },
        {
          location: "Maarssen",
          dealer: "Aqua Sport Shop",
          website: "http://www.aquasportshop.com",
        },
        {
          location: "Maasbracht",
          dealer: "Boatequipment",
          website: "http://www.boatequipment.nl",
        },
        {
          location: "Maaseik",
          dealer: "Leyen Dierenspeciaalzaak",
          website: "http://www.leyendierenspeciaalzaak.be",
        },
        {
          location: "Malden",
          dealer: "Austria Ski & Wandelsport",
          website: "http://www.austriasport.nl",
        },
        {
          location: "Malden",
          dealer: "Teezz Jeanswear",
          website: "https://www.teezz-jeanswear.nl",
        },
        {
          location: "Meijel",
          dealer: "Meeuwis Schoenen BV",
          website: "http://www.meeuwis-meijel.nl",
        },
        {
          location: "Meppel",
          dealer: "Boatland/Boatservice Dannijs BV",
          website: "http://www.boatland.nl",
        },
        {
          location: "Middelburg",
          dealer: "BdStore",
          website: "http://www.bdstore.com",
        },
        {
          location: "Montfoort",
          dealer: "Atorka Ruitersport",
          website: "http://www.atorka.nl",
        },
        {
          location: "Nieuwegein",
          dealer: "Unique Jacht en Outdoor",
          website: "http://www.uniquejachtenoutdoor.nl",
        },
        {
          location: "Nieuwpoort",
          dealer: "New Port bvba",
          website: "http://www.new-port.be",
          country: "BE",
        },
        {
          location: "Nieuwpoort",
          dealer: "Nieuwpoort Marine Chandler",
          website: "http://www.nieuwpoortmarine1.com",
          country: "BE",
        },
        {
          location: "Nijmegen",
          dealer: "'t Schotse Schaap",
          phone: "06-11924695",
        },
        {
          location: "Nijmegen",
          dealer: "Schoenmode Derks",
          website: "http://www.derksschoenen.nl",
        },
        {
          location: "Nijmegen",
          dealer: "ByBenedicte",
          website: "http://www.bybenedicte.nl",
        },
        {
          location: "Nijmegen",
          dealer: "Alexander de Vries herenmode",
          phone: "024-3030301",
        },
        {
          location: "Nijmegen",
          dealer: "Spac Sport",
          website: "http://www.spacsport.nl",
        },
        {
          location: "Nijmegen",
          dealer: "Het Heydenrijck",
          website: "http://www.heydenrijck.nl",
        },
        {
          location: "Nijmegen",
          dealer: "Hills Mode",
          website: "http://www.hillsmode.nl",
        },
        {
          location: "Nuth",
          dealer: "Bemelmans Ruitersport",
          website: "http://www.bemelmansnooitvangehoord.nl",
        },
        {
          location: "Odijk",
          dealer: "Huntress Clothing",
          website: "http://www.huntress.nl",
        },
        {
          location: "Odiliapeel",
          dealer: "Jachtloods",
          website: "http://www.jachtloods.nl",
        },
        {
          location: "Oisterwijk",
          dealer: "Zweerts Schoenen",
          website: "http://www.zweerts-schoenen.nl",
        },
        {
          location: "Onstwedde",
          dealer: "Schoenenhuis Drenth",
          phone: "0599-331371",
        },
        {
          location: "Ootmarsum",
          dealer: "Chapeaux Schoenen",
          website: "http://www.chapeaux-schoenen.nl",
        },
        {
          location: "Orvelte",
          dealer: "Groot Goed",
          website: "http://www.buitenwinkel-orvelte.nl",
        },
        {
          location: "Orvelte",
          dealer: "De Schenkerij",
          website: "http://www.deschenkerij.nl",
        },
        {
          location: "Ouddorp",
          dealer: "Monosails",
          website: "http://www.monosails.nl",
        },
        {
          location: "Oudenbosch",
          dealer: "Animal World",
          website: "http://www.animalworld.nl",
        },
        {
          location: "Ouderkerk aan den Ijssel",
          dealer: "Tiendhoek Tuin en Buitencentrum",
          website: "http://www.tiendhoek.com",
        },
        {
          location: "Oudeschild Texel",
          dealer: "Watersport Texel",
          website: "http://www.watersporttexel.nl",
        },
        {
          location: "Oudewater",
          dealer: "Fauna Plaza",
          website: "http://www.faunaplaza.nl",
        },
        {
          location: "Oudsbergen",
          dealer: "De Jachtkelder",
          website: "http://www.dejachtkelder.be",
          country: "BE",
        },
        {
          location: "Oud Loosdrecht",
          dealer: "Freeken Watersport",
          website: "http://www.vreeken.com",
        },
        {
          location: "Plasmolen",
          dealer: "Jachthaven Eldorado",
          website: "http://www.jachthaveneldorado.nl",
        },
        {
          location: "Rijen",
          dealer: "de Barones Ruitersport",
          website: "http://www.debaronesruitersport.nl",
        },
        {
          location: "Rosendahl",
          dealer: "Hemker Greensport",
          website: "http://www.hemker-greensport.de",
          country: "DE",
        },
        {
          location: "Rotterdam",
          dealer: "Correct Bike & Boot",
          website: "http://www.correct.nl",
        },
        {
          location: "Rotterdam",
          dealer: "Vervat",
          website: "http://www.vervat.nl",
        },
        {
          location: "Ruinen",
          dealer: "Het Land-Goed",
          website: "http://www.hetland-goed.nl",
        },
        {
          location: "Schinveld",
          dealer: "Ruitersport Limburg",
          website: "http://www.deonderstemolen.nl",
        },
        {
          location: "Sint Annaland",
          dealer: "Mariteam Shipyard",
          website: "http://www.mariteamshipyard.nl",
        },
        {
          location: "Sint Oedenerode",
          dealer: "Peppelhoeve",
          website: "http://www.peppelhoeve.nl",
        },
        {
          location: "Sluis",
          dealer: "Groosman Schoenmode",
          website: "http://www.schoenensluis.nl",
        },
        {
          location: "Sneek",
          dealer: "The Lake House",
          website: "http://www.thelakehouse.nl",
        },
        {
          location: "Son",
          dealer: "Offroad & Adventures",
          website: "http://www.offroad-adventures.nl",
        },
        {
          location: "Stadskanaal",
          dealer: "Pengels Outdoor",
          website: "http://www.pengels.eu",
        },
        {
          location: "Tilburg",
          dealer: "Snow Travel & Adventure Store",
          website: "http://www.sportieftilburg.nl",
        },
        {
          location: "Twello",
          dealer: "Hemker & Bekking",
          website: "https://www.hemker-bekking.nl",
          premium: true,
        },
        {
          location: "Veenendaal",
          dealer: "'t Peerdewinkeltje",
          website: "http://www.letty.nl",
        },
        {
          location: "Vlieland",
          dealer: "Port of Vlie",
          website: "http://www.portofvlie.nl",
        },
        {
          location: "Vlimmeren/Beerse",
          dealer: "Royal Horse Shop",
          website: "http://www.royalhorseshop.com",
          country: "BE",
        },
        {
          location: "Vlissingen",
          dealer: "Ruitersport Sissi",
          website: "http://www.ruitersportsissi.nl",
        },
        {
          location: "Vlissingen",
          dealer: "Van de Gruiter BV",
          website: "http://www.vandegruiter.com",
        },
        {
          location: "Volendam",
          dealer: "Schroder Watersport",
          website: "http://www.schroderwatersport.nl",
        },
        {
          location: "Voorburg",
          dealer: "Pet-Towel",
          website: "http://www.pettowel.nl",
        },
        {
          location: "Vorden",
          dealer: "Jacht en Wapenhandel Martens",
          website: "http://www.martensvorden.nl",
        },
        {
          location: "Vorselaar",
          dealer: "AVS-Shop Vorselaar",
          website: "http://www.avs-shop.be",
          country: "BE",
        },
        {
          location: "Waalre",
          dealer: "Colinda",
          website: "http://www.dierenwinkel-in.nl",
        },
        {
          location: "Waddinxveen",
          dealer: "Actieve Honden.com",
          website: "http://www.hondenspellen.com",
        },
        {
          location: "Wageningen",
          dealer: "Van den Biggelaar schoenen",
          website: "http://www.biggelaar-schoenen.nl",
        },
        {
          location: "Wassenaar",
          dealer: "Sportshop Mol",
          website: "http://www.sportshop.nl",
        },
        {
          location: "Wemeldinge",
          dealer: "Boateak",
          website: "http://www.boateak.nl",
        },
        {
          location: "Willemstad",
          dealer: "Yachting Willemstad",
          website: "http://www.yachtingwillemstad.nl",
        },
        {
          location: "Wissenkerke",
          dealer: "The King's Elephant",
          website: "http://www.thekingselephant.com",
        },
        {
          location: "Wolphaartsdijk",
          dealer: "De Zuidschor Watersport BV",
          website: "http://www.zuidschor.nl",
        },
        {
          location: "Wolvega",
          dealer: "Kamsma Schoenen",
          website: "https://www.kamsmaschoenen.nl",
        },
        {
          location: "Workum",
          dealer: "Noarderwind",
          website: "http://www.noarderwind.nl",
        },
        {
          location: "Woubrugge",
          dealer: "Westernspul VOF",
          website: "http://www.westernspul.nl",
        },
        {
          location: "Yerseke",
          dealer: "Desto Ruitersport",
          website: "http://www.destoruitersport.nl",
        },
        {
          location: "Zandvoort",
          dealer: "De Bode",
          website: "http://www.de-bode.nl",
        },
        {
          location: "Zierikzee",
          dealer: "Bouwman Watersport",
          website: "http://www.bouwmanwatersport.nl",
        },
        {
          location: "Zuidlaren",
          dealer: "Ruchti",
          website: "http://www.ruchti.nl",
        },
        {
          location: "Zutendaal",
          dealer: "Ruitersport Hermans",
          website: "http://www.ruitersporthermans.be",
          country: "BE",
        },
        {
          location: "Zutphen",
          dealer: "John Bardale",
          website: "http://www.johnbardale.nl",
        },
      ],
    };
  },
  computed: {
    rowProps() {
      return (data) => {
        return {
          class: data.item.premium === true ? "bold-row" : "",
        };
      };
    },
    tableHeaders() {
      return [
        { key: "location", title: this.$t("dealers.location") },
        { key: "dealer", title: this.$t("dealers.dealer") },
        // { key: "hasBoots", title: "Boots" },
        // { key: "hasBoatshoes", title: "Boat shoes" },
        { key: "country", title: this.$t("dealers.country") },
        { key: "contact", title: this.$t("dealers.contact") },
      ];
    },
  },
};
</script>

<style>
.bold-row {
  font-weight: bold;
}
</style>
