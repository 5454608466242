<template>
  <BookletViewer asset_path="booklets/docks/" :list_of_pages="pages" />
</template>

<script>
import BookletViewer from "../components/BookletViewer.vue";
export default {
  components: {
    BookletViewer,
  },
  data: function () {
    return {
      pages: [
        ["front.png"],
        [
          {
            name: "W202 Bridgewater",
            color: "colors.beige",
            size_min: 37,
            size_max: 41,
            price: 119,
            description: "shoes.boatshoes.w202",
          },
          "w202.png",
        ],
        [
          {
            name: "W204 Bridgewater",
            color: "colors.blue",
            size_min: 37,
            size_max: 41,
            price: 119,
            description: "shoes.boatshoes.w204",
          },
          "w204.png",
        ],
        [
          {
            name: "W205 Bridgewater",
            color: "colors.deep-blue",
            size_min: 37,
            size_max: 41,
            price: 119,
            description: "shoes.boatshoes.w205",
          },
          "w205.png",
        ],
        [
          {
            name: "M203 Bridgewater",
            color: "colors.taupe",
            size_min: 41,
            size_max: 45,
            price: 119,
            description: "shoes.boatshoes.m203",
          },
          "m203.png",
        ],
        [
          {
            name: "M206 Bridgewater",
            color: "colors.blue",
            size_min: 41,
            size_max: 45,
            price: 119,
            description: "shoes.boatshoes.m206",
          },
          "m206.png",
        ],
        [
          {
            name: "M207 Bridgewater",
            color: "colors.deep-blue",
            size_min: 41,
            size_max: 45,
            price: 119,
            description: "shoes.boatshoes.m207",
          },
          "m207.png",
        ],
        [
          {
            name: "M208 Highbridge",
            color: "colors.taupe",
            size_min: 41,
            size_max: 45,
            price: 139,
            description: "shoes.boatshoes.m208",
          },
          "m208.png",
        ],
        [
          {
            name: "M209 Highbridge",
            color: "colors.deep-blue",
            size_min: 41,
            size_max: 45,
            price: 139,
            description: "shoes.boatshoes.m209",
          },
          "m209.png",
        ],
        [
          {
            name: "M2010 Highbridge",
            color: "colors.caramelo",
            size_min: 41,
            size_max: 45,
            price: 139,
            description: "shoes.boatshoes.m2010",
          },
          "m2010.png",
        ],
        [
          {
            name: "M2011 Highbridge",
            color: "colors.salter",
            size_min: 41,
            size_max: 45,
            price: 139,
            description: "shoes.boatshoes.m2011",
          },
          "m2011.png",
        ],
        ["riemenbox.png"],
        ["boatshoes3.jpg"],
        [
          {
            name: "U705 Docks",
            color: "colors.brown",
            size_min: 41,
            size_max: 46,
            price: 119,
            description: "shoes.boatshoes.u705",
          },
          "u705.png",
        ],
        [
          {
            name: "U706 Docks",
            color: "colors.brown-white-sole",
            size_min: 41,
            size_max: 46,
            price: 119,
            description: "shoes.boatshoes.u706",
          },
          "u706.png",
        ],
        [
          {
            name: "U707 Docks",
            color: "colors.brown-navy",
            size_min: 41,
            size_max: 46,
            price: 119,
            description: "shoes.boatshoes.u707",
          },
          "u707.png",
        ],
        ["mini_display.png"],
        ["miniposter.png"],
      ],
    };
  },
};
</script>
