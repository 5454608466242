<template>
  <BookletViewer asset_path="booklets/somerton/" :list_of_pages="pages" />
</template>

<script>
import BookletViewer from "../components/BookletViewer.vue";
export default {
  components: {
    BookletViewer,
  },
  data: function () {
    return {
      pages: [
        ["somerton-nl.png"],
        [
          {
            name: "U801 Somerton",
            color: "colors.navy",
            size_min: 38,
            size_max: 47,
            price: 149,
            description: "shoes.boatshoes.u801",
          },
          "u801.png",
        ],
        [
          {
            name: "U802 Somerton",
            color: "colors.brown",
            size_min: 38,
            size_max: 47,
            price: 149,
            description: "shoes.boatshoes.u802",
          },
          "u802.png",
        ],
        [
          {
            name: "U803 Somerton",
            color: "colors.dark-brown",
            size_min: 41,
            size_max: 47,
            price: 149,
            description: "shoes.boatshoes.u803",
          },
          "u803.png",
        ],
        [
          {
            name: "U804 Somerton",
            color: "colors.jeans",
            size_min: 38,
            size_max: 46,
            price: 149,
            description: "shoes.boatshoes.u804",
          },
          "u804.jpg",
        ],
        [
          {
            name: "U805 Somerton",
            color: "colors.cognac",
            size_min: 39,
            size_max: 46,
            price: 149,
            description: "shoes.boatshoes.u805",
          },
          "u805.jpg",
        ],
        ["miniposter.png"],
      ],
    };
  },
  watch: {
    "$i18n.locale": function () {
      this.updatePoster();
    },
  },
  methods: {
    updatePoster() {
      this.pages[0] = ["somerton-" + this.$i18n.locale + ".png"];
    },
  },
};
</script>
