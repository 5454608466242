<template>
  <BookletViewer asset_path="booklets/boatshoes/" :list_of_pages="pages" />
</template>

<script>
import BookletViewer from "../components/BookletViewer.vue";
export default {
  components: {
    BookletViewer,
  },
  data: function () {
    return {
      pages: [
        ["boot1.jpg"],
        ["boot2.jpg"],
        ["boot4.jpg"],
        ["boot6.jpg"],
        ["boatshoes2.jpg"],
        ["boatshoes5.jpg"],
        ["boot5.jpg"],
        [
          {
            name: "M304 Cheddar",
            color: "colors.brown",
            size_min: 41,
            size_max: 46,
            price: 129,
            description: "shoes.boatshoes.m304",
          },
          "m304.png",
        ],
        [
          {
            name: "U901 Watchet",
            color: "colors.navy",
            size_min: 39,
            size_max: 46,
            price: 139,
            description: "shoes.boatshoes.u901",
          },
          "u901.png",
        ],
        [
          {
            name: "U902 Watchet",
            color: "colors.cognac",
            size_min: 39,
            size_max: 46,
            price: 139,
            description: "shoes.boatshoes.u902",
          },
          "u902.jpg",
        ],
        ["miniposter.png"],
      ],
    };
  },
};
</script>
