<template>
  <BookletViewer asset_path="booklets/boatshoes2/" :list_of_pages="pages" />
</template>

<script>
import BookletViewer from "../components/BookletViewer.vue";
export default {
  components: {
    BookletViewer,
  },
  data: function () {
    return {
      pages: [
        ["boatshoes1.jpg"],
        ["somerton.png"],
        ["boatshoes2.jpg"],
        ["boatshoes3.jpg"],
        ["boatshoes4.jpg"],
        ["boatshoes5.jpg"],
        [
          {
            name: "U801 Somerton",
            color: "Navy",
            size_min: 38,
            size_max: 47,
            price: 149,
            description: "Volledig lederen boatshoes.",
          },
          "u801.png",
        ],
        [
          {
            name: "U802 Somerton",
            color: "Brown",
            size_min: 38,
            size_max: 47,
            price: 149,
            description: "Volledig lederen boatshoes.",
          },
          "u802.png",
        ],
        [
          {
            name: "U803 Somerton",
            color: "Dark brown",
            size_min: 41,
            size_max: 47,
            price: 149,
            description: "Volledig lederen boatshoes.",
          },
          "u803.png",
        ],
        [
          {
            name: "U901 Watchet",
            color: "Navy",
            size_min: 39,
            size_max: 46,
            price: 139,
            description: "Volledig lederen boatshoes.",
          },
          "u901.png",
        ],
        ["miniposter.png"],
      ],
    };
  },
};
</script>
